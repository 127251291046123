import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/index";
import Document from "./pages/document/index";
import NewDocument from "./pages/document/new";
import NotFound from "./pages/not-found";
import * as serviceWorker from "./serviceWorker";


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="document/new" element={<NewDocument />} />
                <Route path="document/:id" element={<Document />} />
                <Route path="document" element={<Document />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorker.register();