import React from "react";
import "./index.css";
import MainCard from '../components/main-card'

export default function Home() {

  const cf = (freq) => ({color: `hsla(${Math.round(0.92*freq - 405)}, 100%, 50%, @)`, freq});

  const onClick = (code) => { console.log(code); };

  return (
    <div className="container">
      <main>
        <div className="grid">
          <div className="row">
            <MainCard code="0" name="A" {...cf(440.00)} onClick={onClick} />
            <MainCard code="1" name="?" {...cf(466.16)} onClick={onClick} />
            <MainCard code="2" name="B" {...cf(493.88)} onClick={onClick} />
          </div>
          <div className="row">
            <MainCard code="3" name="C" {...cf(523.25)} onClick={onClick} />
            <MainCard code="4" name="?" {...cf(554.37)} onClick={onClick} />
            <MainCard code="5" name="D" {...cf(587.33)} onClick={onClick} />
          </div>
          <div className="row">
            <MainCard code="6" name="?" {...cf(622.25)} onClick={onClick} />
            <MainCard code="7" name="E" {...cf(659.25)} onClick={onClick} />
            <MainCard code="8" name="F" {...cf(698.46)} onClick={onClick} />
          </div>
          <div className="row">
            <MainCard code="9" name="?" {...cf(739.99)} onClick={onClick} />
            <MainCard code="a" name="G" {...cf(783.99)} onClick={onClick} />
            <MainCard code="b" name="?" {...cf(830.61)} onClick={onClick} />
          </div>
        </div>
      </main>
    </div>
  )
}
