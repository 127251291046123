import React from "react";


export default function NotFound() {
    return (
        <>
            <div className="stateMessage">
                Page not found
            </div>
        </>
    )
}