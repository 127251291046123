import React, { useCallback, useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import "./style.css";
import Axios from 'axios';
import { apiUrl } from '../../functions';
import { DocumentApi } from '../../utils/document';


export default function NewDocumentPage() {
    const [error, setError] = useState(false);
    const [createdId, setCreatedId] = useState("");
    const fetchNewDocument = useCallback(async () => {
        const resp = await Axios.get(apiUrl(DocumentApi.Create));
        const id = resp.data.id;
        if (resp.status === 200 && id) {
            setCreatedId(resp.data.id);
            // router.push({
            //     pathname: '/document',
            //     query: {
            //         id: resp.data.id,
            //     },
            // });
        } else {
            setError(true);
        }
    }, []);
    useEffect(() => {
        fetchNewDocument();
    });

    if (createdId) {
        return <Navigate to={`/document/${createdId}`} />;
    } else {
        return (
            <>
                <div className="stateMessage">
                    {error ? 'Error. Try one more time.' : 'Creating new document...'}
                </div>
            </>
        );
    }
}