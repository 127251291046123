import 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/quickbars';
import { Editor } from '@tinymce/tinymce-react';

export type ConfiguredEditorProps = {
    initialContent: string,
    onContentChange: (content: string) => void,
}

export default function ConfiguredEditor({ initialContent, onContentChange }: ConfiguredEditorProps) {
    return (
        <Editor
            initialValue={initialContent}
            inline={true}
            init={{
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste codesample wordcount autosave quickbars'
                ],
                toolbar: false,
                autosave_interval: '10s',
                quickbars_insert_toolbar: 'quicktable image media codesample',
                quickbars_selection_toolbar: `bold italic underline | formatselect 
                            | alignleft aligncenter alignright alignfull | blockquote quicklink`,
                contextmenu: 'undo redo | inserttable | cell row column deletetable',
                powerpaste_word_import: 'clean',
                powerpaste_html_import: 'clean',
                skin_url: '/assets/libs/tinymce/skins/ui/oxide',
                content_css: [
                    '/assets/libs/tinymce/skins/content/default/content.min.css',
                    '/assets/styles/editor.css'
                ]
            }}
            scriptLoading={{
                defer: true,
            }}
            onEditorChange={(content, _) => onContentChange(content)}
        />
    );
}