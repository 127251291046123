import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import 'fontsource-roboto';

import "./style.css";
import Axios from 'axios';
import { apiUrl } from '../../functions';
import { DocumentApi } from '../../utils/document';
import Button from '@material-ui/core/Button';
import Editor from "../../components/editor";


const CONTENT_STUB = '<h1>New Document</h1><p>Content</p>';

// const DymanicEditorWithNoSSR = dynamic(
//     () => import('../../components/editor'),
//     { ssr: false }
// );

export default function DocumentPage() {
    const { id } = useParams();
    const [content, setContent] = useState(null);
    const [originalContent, setOriginalContent] = useState(null);
    const [error, setError] = useState([null as string, true]);
    const [loading, setLoading] = useState(false);

    const fetchText = useCallback(async () => {
        console.log("fetch");
        // console.log(router.query);
        console.log(id);
        if (!id) {
            setError(["Provide id, please", true]);
        } else {
            setLoading(true);
            const resp = await Axios.get(apiUrl(DocumentApi.ById, { path: { id } })).catch(_ => ({ data: { error: true } }));
            setLoading(false);
            if (!resp.data.error) {
                setContent(resp.data.content ?? CONTENT_STUB);
                setOriginalContent(resp.data.content ?? CONTENT_STUB);
                setError(null);
            } else {
                setError(["Document not found", true]);
            }
        }
    }, [id]);

    useEffect(() => {
        fetchText();
    }, [fetchText, id]);

    const fetchSaveContent = useCallback(async () => {
        setLoading(true);
        const resp = await Axios.post(apiUrl(DocumentApi.ById, { path: { id } }), { content }).catch(_ => ({ data: {} }));
        setLoading(false);
        if (resp.data.updated) {
            setOriginalContent(content);
        } else {
            setError(['Could not save. Try again.', false]);
        }
    }, [content, id]);

    const handleContentChange = (content) => {
        setContent(content);
        setError([null, true]);
    };

    return (
        <>
            {loading &&
                <div id="loading" className="stateMessage">Loading...</div>
            }
            {!loading && error?.[0] &&
                <div id="error" className="stateMessage">{error}</div>
            }
            {(!error?.[1] || !error?.[0]) && id &&
                <div id="editor-container">
                    <Editor
                        initialContent={content}
                        onContentChange={handleContentChange} />
                    {/* <DymanicEditorWithNoSSR
                        initialContent={content} 
                        onContentChange={handleContentChange} /> */}
                </div>
            }
            {originalContent !== content && 
                <div className="buttons-container">
                    <Button variant="contained"
                            color="primary"
                            onClick={fetchSaveContent}>
                        Save
                    </Button>
                </div>
            }
        </>
    );
}