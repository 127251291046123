import React, { useEffect, useRef } from "react";
import styled from "styled-components";


export default function MainCard({ code, name, color = "", freq, onClick }) {

    const oscillator = useRef(null);
    const audioContext = useRef(null);

    useEffect(function mount() {
        if (window.AudioContext) {
            audioContext.current = new window.AudioContext();
        }
    });
    
    const play = () => {
        const context = audioContext.current;
        if (context) {
            let osc = oscillator.current;
            if (osc) osc.stop();
            osc = context.createOscillator();
            osc.type = "sine";
            osc.frequency.value = freq;
            osc.connect(context.destination);
            osc.start();
            oscillator.current = osc;
        }
    };
    const stop = () => {
        if (oscillator.current) oscillator.current.stop();
        oscillator.current = null;
    };

    return (
        <>
            <Card
                color={color}
                onMouseDown={play}
                onMouseUp={stop}
                onMouseLeave={stop}
                onClick={() => onClick && onClick(code)}
                >
                {name}
            </Card>
        </>
    );
}

const Card = styled.div`
    margin: 1rem;
    height: 4rem;
    width: 4rem;
    line-height: 4rem;
    text-align: center;
    text-decoration: none;
    color: ${props => props.color.replace('@', '0.86')};
    background-color: #eaeaea7f;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    user-select: none;

    &:hover {
        background-color: ${props => props.color.replace('@', '0.1')};
    }
    &:focus {
        background-color: ${props => props.color.replace('@', '0.1')};
    }

    &:active {
        background-color: ${props => props.color.replace('@', '0.2')};
    }
`;