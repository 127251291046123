import qs from 'querystring';


/* 
 * If using emulator then url for method `api/getTick` is like
 * http://localhost:5001/common-bca/europe-west2/api/getTick/1
 * If no emulator then
 * https://europe-west2-common-bca.cloudfunctions.net/api/getTick/1
 */
const useEmulator = false && process.env.NODE_ENV !== "production";
const appName = 'common-bca';
const region = 'europe-west2';
const emulatorHostPort = ['localhost', 5001];

function emulatorBaseUrl() {
    const [host, port] = emulatorHostPort;
    return `http://${host}:${port}/${appName}/${region}`;
}

function realBaseUrl() {
    return `https://${region}-${appName}.cloudfunctions.net`;
}

function baseUrl() {
    return useEmulator ? emulatorBaseUrl() : realBaseUrl();
}

export const TestApiFunction = {
    GetTick: '/api/getTick/{key}'
}

export type ApiFunctionOpts = {
    path?: {};
    query?: {};
}

export function apiUrl(apiFunction: string, opts?: ApiFunctionOpts): string {
    const path = apiFunction.replace(/{(\w+)}/g, (_, key) => opts?.path?.[key]?.toString() ?? '');
    const queryString = opts?.query ? `?${qs.stringify(opts.query)}` : '';
    return baseUrl() + path + queryString;
}